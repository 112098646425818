.App {
    /* text-align: center; */
    width: 100%;
    margin: 0 auto;
    /* background: linear-gradient(rgb(240, 232, 232), white); */
    background: white;
}

.App-header {   
    margin: auto;
    top: 0;
    width: 100%;
    min-height: 45vh;
    display: flex;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    background-image: url("images/tech.jpg");
    background-size: 200%;
    animation: fadein 9s;
}
@keyframes fadein{
    from { opacity: 0;}
    to { opacity: 1;}
}

@keyframes fadeinfromBottom{
    0%{
        opacity:0;
        transform: translateY(1500%)
    }
    100%{
        opacity: 1;
        transform: translateY(0)
    }
}
@keyframes picEnter {
    0%{
        transform: translateY(-1000%)
    }
    100%{
        transform: translateY(0)
    }
}

.topLink{
    color: black;
    display:flex;
    justify-content: center;
    width: 90px;
    height: 30px;
    margin: auto;
    position: fixed;
    /* opacity: .2; */
    top: 45%;
    right: 25px;
    animation: fadein 5s;
    /* animation-delay: 4s; */
    background: whitesmoke;
    border-radius: 25px;
    box-shadow: -4px 2px 2px silver;
    
}
.topLink a{
    text-decoration: none;
    font-size: 1.2rem;
    color: rgb(32, 5, 107);
    


}

.navbar {
    margin: auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: space-between;
}

.nameTitle {
    color: white;
    /* margin-left: 40px;
    top: 100px;
    position: absolute; */
    margin: auto;
    animation: nameEnter 4s ;
    animation-direction: normal;
    animation-iteration-count: 1;
}
@keyframes nameEnter{
    0%{
        transform: translateX(500%);
    }
    100%{
        transform: translateX(0);
    }
}


div.iconLinks{
    width: 25%;
    position: absolute;
    top: 50%;
    left: 38%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
    animation: fadeinfromBottom 5s;
 

}

.skills{
    animation: fadein 5s;
    /* animation-delay:  */
    /* margin-top: 200px; */
    margin-bottom: 250px;
}

.aboutMeImg {
    margin: auto;
    width: 300px;
    border-radius: 50%;
    animation: picEnter 4s;
    animation-direction: normal;
    animation-iteration-count: 1;
    
}
section.homePage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    margin-top:300px;
    /* margin-left: 100px; */
    width: 60%;
}
.maketitle{
    display: flex;
    justify-content: center;
    font-size: 2.5rem;
    margin: auto;
}
.maketitle2{
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    margin: auto;
    padding:50px;
}

div.iconPics{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
div.iconPics img{

    padding: 25px;
    width: 150px;
    height: 150px;
    border-radius: 15%;
}

section.about-words{
    width: 100%;
    background: url("images/aboutBackground.jpg");
    background-size:cover;
    color: white;
}
div.one-about{
    width: 100%;
    margin: 0 auto;
    background-color: rgb(128,128,128,0.3);
    padding-bottom: 100px;
}
div.one-about h4{
    padding-top: 20px;
    margin:auto;
    display: flex;
    justify-content: center;
}
div.one-about p{
    width: 25%;
    position: relative;
    left: 15px;
}
.Hone{
    /* something here */
    background: grey;
}

.Htwo {
    /* sometthing here */
    background:grey;
}
.nav-links {
    /* width: 15%; */
    /* width: 50%; */
    height: 25%;
    text-align: center;
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 20px;
    top: 50%;
    justify-content: space-evenly;
    justify-items: flex-end;
    margin: 5px;
}

.navlink {
    text-decoration: none;
    font-size: 1.2rem;
    color:black;
    /* may be good idea to change link color to match up accross the board here */
    width:90px;
    height: 30px;
    margin: 3px auto;
    background: whitesmoke;
    border-radius: 25px;
    box-shadow: -4px 2px 2px silver;

}
/* .ProjectSection{
    border: 2px solid green;
} */
.projects-container{
    margin: auto;
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    align-content: center;

}
@media only screen and (max-width: 750px) {
    .p-container {
        display: flex;
        flex-direction: column;
        align-content: center;
    }
}

.p-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 0 auto;
    /* background: white; */
}

h3 {
    margin: 10px auto;
}

.project {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    margin-top: 50px;
    margin-bottom: 40px;
    /* background: whitesmoke; */
    /* border-radius: 25px; */
}

@media only screen and (max-width: 750px) {
    .project {
        width: 70%;
        margin-top: 50px;
        margin-bottom: 40px;
        background: whitesmoke;
        /* border-radius: 25px; */
    }
}


.project-img{
    display: flex;
    justify-content: center;
    margin: auto;
    width: 100%;
    max-height: 100%;
    padding: 10px 0px;
}


.project h2{
    /* background: silver; */
    display: flex;
    justify-self: center;
    margin: 0 auto;
}
.project-title{
    text-decoration: none;
    color: black;
}

@media only screen and (max-width: 750px) {
    .project-img {
        width: 100%;
        height: 100%;
        /* padding: 50px; */
        display: flex;
        justify-self: center;
        margin: auto;
    }

    .project h2{
        display: flex;
        justify-content: center;
    }
}

.aboutTopContainer {
    display: flex;
    justify-items: space-between;
}


.Gcard-container {
    margin-top: 10px;
}

.gCard,
.Lcard {
    padding-left: 20px;
}

.resume-container{
    margin-top: 200px;
}
.inProgress-container{
    margin: auto;
    margin-top: 200px;
}


/* RESUME page styling */
.resume-container{
    width: 90%;
    margin: 2% auto;
}
/* lists inline links to career profiles/resumes */
.profileLinks {
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
}
/* setup the look of the links */
.profileLinks h4 a{
    text-decoration: none;
    color: black;
}
.profileLinks h4 a:hover{
   text-decoration-line: underline ;
   font-size: 1.1rem;
}

ƒ
/* separate into 2 or 3 columns */

.skillsTop{
    /* width: 100%; */
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
    border: 5px blue solid;
}
.skills-container{
    display:flex;
}

.devSkills, .softSkills, .personalInterests{
    width: 25%;
    border:1px red dotted;
    /* padding: 1%; */
    margin: 1%;
    background: rgb(248, 249, 242);
}

/* this is the other sections */
#education {
    width: 80%;
    border: 1px solid black;
}

.qrcode{
    width: 100px;
    height: 100px;
}




/* footer */

.footer-container{
    background: black;
    color: slategrey;
}
.footerUsableSpace{
    width: 90%;
    /* display: flex;
    justify-content: center; */
    margin: 0 auto;
    padding-top: 100px;
}
.footerSquare{
    display: block;
    /* border: 1px grey solid; */
}
.footerSquare h5{
    margin: 0 auto;
    display: flex;
    justify-content: center;
}
.footerUsableSpace h4{
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-bottom: 60px;

}
.qrGrid{
    display: flex;
    /* flex-direction: row; */
    justify-content: space-evenly;

}

.creditSection{
    width: 60%;
    margin: 0 auto;
    margin-top: 100px;
    /* border: 2px solid grey; */
}

.creditTitle{
    font-size: .6rem;
}

.cute-note {
font-size: .4rem;
}

/* PERSONAL PAGE STYLING */

.personal-container{
    margin: 0 auto;
    margin-top: 150px;
    width: 45%;
}

/* InProgress page */
.inProgress-container{
    width: 45%;
    margin: 0 auto;
}

/* Teehee section */
.titanic{
    float: none;
}
.jack{
    background: blue;
    float: none
}
.rose{
    float: right;
}